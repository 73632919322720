import { BaseModel } from 'modules/core/models/base-model';

export class AdditionalInformation extends BaseModel {
    id               = null;
    number           = null;
    type_id          = null;
    scope            = null;
    description      = null;
    standard         = null;
    referencing_data = null;
    file_id          = null;
    observations     = null;
}
