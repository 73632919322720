import { BaseModel } from 'modules/core/models/base-model';

/**
 * ID's
 * @type {int}
 */

const SOILS_NT_LAB = 1;

const SOILS_T_LAB = 2;

const AGGREGATES_NT_LAB = 3;

const AGGREGATES_T_LAB = 4;

const SOILS_NT_IN_SITU = 5;

const SOILS_T_IN_SITU = 6;

const AGGREGATES_NT_IN_SITU = 7;

const AGGREGATES_T_IN_SITU = 8;

/**
 * SLUGS
 * @type {string}
 */

export const IN_SITU_WORK_CONTROL = 'in_situ_work_control';

export const LAB_WORK_CONTROL = 'lab_work_control';

export const LAB_USE_IN = 'lab_use_in';

export const LAB_MATERIAL_CHARACTERIZATION = 'lab_material_characterization';

export class AssociationSector extends BaseModel {
    id       = null;
    category = null;
    name     = null;

    get fieldSlugs() {
        switch (this.id) {
            case SOILS_NT_LAB:
                return [LAB_WORK_CONTROL, LAB_MATERIAL_CHARACTERIZATION];
            case SOILS_T_LAB:
                return [LAB_WORK_CONTROL, LAB_MATERIAL_CHARACTERIZATION];
            case AGGREGATES_NT_LAB:
                return [LAB_WORK_CONTROL, LAB_USE_IN, LAB_MATERIAL_CHARACTERIZATION];
            case AGGREGATES_T_LAB:
                return [LAB_WORK_CONTROL, LAB_USE_IN, LAB_MATERIAL_CHARACTERIZATION];
            case SOILS_NT_IN_SITU:
                return [IN_SITU_WORK_CONTROL];
            case SOILS_T_IN_SITU:
                return [IN_SITU_WORK_CONTROL];
            case AGGREGATES_NT_IN_SITU:
                return [IN_SITU_WORK_CONTROL];
            case AGGREGATES_T_IN_SITU:
                return [IN_SITU_WORK_CONTROL];
            default:
                return [LAB_WORK_CONTROL, LAB_USE_IN, LAB_MATERIAL_CHARACTERIZATION];
        }
    }

    get inSituWorkControl() {
        return IN_SITU_WORK_CONTROL;
    }

    get labWorkControl() {
        return LAB_WORK_CONTROL;
    }

    get labUseIn() {
        return LAB_USE_IN;
    }

    get labMaterialCharacterization() {
        return LAB_MATERIAL_CHARACTERIZATION;
    }


}
