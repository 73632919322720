import { BaseModel } from 'modules/core/models/base-model';

export class AssociationSectorField extends BaseModel {
    id        = null;
    slug      = null;
    sector_id = null;
    test_id   = null;
    sample_id = null;
    pza_id    = null;
    layer_id  = null;
}
